import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from './http-service';
import LocalStorage from '../state/local-storage';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonUtilsService } from './common-utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HomeService implements OnDestroy {
  websiteUrl: string = '';
  currentWebsiteUrl: string = '';
  ssIdParam = 'posese-234wsdez-124wjsuidcx';
  uIdParam = '12jodssd-sdilsd-sdkljs-sekosdfusk';
  catche = new Map<string, any>();
  uniqueId: any;
  sessionId: any;
  source: any;

  constructor(
    private httpService: HttpService,
    private localStorage: LocalStorage,
    private readonly loadingService: NgxUiLoaderService,
    private _commonUtil: CommonUtilsService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private readonly router: Router,

  ) {
    try {
      if (window.localStorage.getItem("fontFamily") != undefined || window.localStorage.getItem("fontFamily") != null) {
        let data = JSON.parse(window.localStorage.getItem("fontFamily") || "")
        this.setFontFamilies(data);
      }
    } catch (exception) { }
    this.uniqueId = this._commonUtil.generateUUID();
    if (this.localStorage.get(this.uIdParam)) {
    }
    else {
      this.localStorage.set(this.uIdParam, this.uniqueId)
    }

    if (this.deviceService.isMobile()) {
      this.source = 'MOBILE'
    }
    else if (this.deviceService.isDesktop()) {
      this.source = 'WEB'
    }
  }
  ngOnDestroy(): void {
    // this.endSession(this.sessionId,this.page)
  }


  setWebSiteUrl() {
    this.websiteUrl = window.location.href;
    if (this.websiteUrl.includes('localhost')) {

      this.websiteUrl = 'https://naturals.dev.simpo.ai/';
    }
    this.currentWebsiteUrl = 'https://' + this.websiteUrl.split('/')[2];
  }


  getWebsiteUrl(): string {
    this.websiteUrl = window.location.href;
    if (this.websiteUrl.includes('localhost')) {
      return 'https://naturals.dev.simpo.ai/';
    }
    return 'https://' + this.websiteUrl.split('/')[2];
  }

  getWebsiteDetails(): Observable<any> {

    this.loadingService.start('scroll');
    let data = {
      websiteUrl: this.currentWebsiteUrl,
    };
    const data$ = new BehaviorSubject<any>({});
    if (this.catche.has(this.currentWebsiteUrl)) {
      const cachedValue = this.catche.get(this.currentWebsiteUrl);
      setTimeout(() => {
        data$.next(cachedValue);
        this.pageHilighter(cachedValue?.websiteContentV3);
      },);
    } else {

      this.getWebsiteDataByWebsiteUrl(data, this.currentWebsiteUrl).subscribe({
        next: (res: any) => {
          const data = res?.data;
          if (data) {
            this.catche.set(this.currentWebsiteUrl, data);
            this.storeWebSiteInfo(data);
            data$.next(data);

            const deliveryOptions = { deliveryChanges: data?.business?.deliveryCharges, minOrderAmount: data?.business?.minOrderAmount };
            this.localStorage.set("deliveryOptions", JSON.stringify(deliveryOptions));
            this.localStorage.set('subIndustryId', data.website?.subIndustryId);
            this.localStorage.set("isPaymentGatewayEnabled", data?.business?.paymentGatewayEnabled);
            this.localStorage.set("websiteType", data?.websiteContentV3?.type);
            this.localStorage.set("loginRequired", data?.business?.loginRequired);
            this.localStorage.set("signUpEnabled", data?.business?.signupEnabled)
            window.localStorage.setItem('fontFamily', JSON.stringify(data?.websiteContentV3));
            if (data?.business?.loginRequired && !this.localStorage.get("perId")) {
              this.router.navigate(["/login"]);
            }
            this.setFontFamilies(data?.websiteContentV3);
            this.pageHilighter(data?.websiteContentV3);
          }
          // this.setFontFamilies(data?.websiteContentV3);
        },
        error: (err) => {
          // console.log(err);
          data$.error(err);
        },
      });
      this.loadingService.stop('scroll');
    }

    this.sessionId = this._commonUtil.generateUUID();
    if (this.localStorage.get(this.ssIdParam)) {
    }
    else {
      this.localStorage.set(this.ssIdParam, this.sessionId);
    }
    return data$.asObservable();
  }

  doDefaultSetup() {

    let data = {
      websiteUrl: this.currentWebsiteUrl,
    };
    this.loadingService.start('scroll');
    try {

      const primaryInfoPromise = this.getWebsiteDataByWebsiteUrl(data, this.currentWebsiteUrl).toPromise();
      primaryInfoPromise.then((res: any) => {
        const data = res?.data;
        data?.websiteContentV3?.components[0]?.content.navbarButtons.forEach((redirect: any) => {
          if (redirect.redirectionUrl == window.location.pathname || redirect.label.toLowerCase().replaceAll(" ", "-").replaceAll("\'", "") == window.location.pathname.split("/")[1]) {
            this.localStorage.set('pId', redirect.pageId);

            const deliveryOptions = { deliveryChanges: data?.business?.deliveryCharges, minOrderAmount: data?.business?.minOrderAmount };
            this.localStorage.set("deliveryOptions", JSON.stringify(deliveryOptions));

            this.localStorage.set("isPaymentGatewayEnabled", data?.business?.paymentGatewayEnabled);
            this.localStorage.set("websiteType", data?.websiteContentV3?.type);
            this.localStorage.set("loginRequired", data?.business?.loginRequired);
            this.localStorage.set("signUpEnabled", data?.business?.signupEnabled)
            this.localStorage.set('subIndustryId', data.website?.subIndustryId);
            if (data?.business?.loginRequired && !this.localStorage.get("perId")) {
              this.router.navigate(["/login"]);
            }
          }
        })
        this.loadingService.stop('scroll');
      },
      );
    } catch (exception) {
      this.loadingService.stop('scroll');
    }
  }

  startSession(pageName: any, pageId: any) {
    let url;
    if (window.location.href.includes("localhost") || window.location.href.includes("http://127.0.0.1")) {
      url = "https://hella.dev.simpo.ai"
    }
    let data = {
      uniqueUserId: this.localStorage.get(this.uIdParam),
      sessionId: this.sessionId,
      pageName: pageName,
      pageId: pageId,
      businessId: window.localStorage.getItem('bId'),
      source: this.source,
      url: url
    }
    const data$ = new BehaviorSubject<any>({});
    if (pageName) {
      this.httpService.post(
        environment.baseBusinessUrl + `analytics/start/session/log`, data).subscribe(
          {
            next: (res) => {
              const data = res?.data;
              data$.next(data);
            },
            error: (err) => {
              // console.log(err);
              data$.error(err);
            }
          }
        );

    }
    return data$.asObservable();
  }

  endSession(pageId: any) {
    let data = {
      sessionId: this.sessionId,
      pageId: pageId
    }
    const data$ = new BehaviorSubject<any>({});
    this.httpService.post(
      environment.baseBusinessUrl + `analytics/end/session/log?sessionId=${data.sessionId}&pageId=${data.pageId}`, data
    ).subscribe(
      {
        next: (res) => {
          const data = res?.data;
          data$.next(data);
        },
        error: (err) => {
          // console.log(err);
          data$.error(err);
        }
      }
    );
    return data$.asObservable();
  }


  // api call
  getWebsiteDataByWebsiteUrl(data: any, websiteUrl: any) {
    return this.httpService.put(
      environment.baseBusinessUrl + `v3/url/data?websiteUrl=${websiteUrl}`,
      data
    );
  }

  getPageDataWithId(id: any): Observable<any> {
    this.loadingService.start('scroll');
    const data$ = new BehaviorSubject<any>({});
    if (this.catche.has(id)) {
      const cachedValue = this.catche.get(id);
      // console.log(cachedValue)
      setTimeout(() => {
        data$.next(cachedValue);
        // console.log(cachedValue)
        // this.pageHilighter(cachedValue)
      }, 0);
    } else {
      this.httpService
        .get(environment.baseBusinessUrl + `v3/page/pageId?pageId=${id}`)
        .subscribe({
          next: (res) => {
            const data = res?.data;
            this.catche.set(id, data);
            data$.next(data);
            // this.pageHilighter(data)
            // this.setFontFamilies(res?.data);
            this.loadingService.stop('scroll');
          },
          error: (err) => {
            this.loadingService.stop('scroll');
            data$.error(err);
          },
        });

      this.sessionId = this._commonUtil.generateUUID();
      if (this.localStorage.get(this.ssIdParam)) {
      }
      else {
        this.localStorage.set(this.ssIdParam, this.sessionId);
      }
    }
    this.loadingService.stop('scroll');

    return data$;
  }

  storeWebSiteInfo(data: any) {
    if (data) {
      this.localStorage.set('websiteData', JSON.stringify(data.websiteContentV3));
      this.localStorage.set('bId', data.website.businessId);
    }
  }

  storeAnalyticsData(data: any) {
    if (data) {
      this.localStorage.set(this.ssIdParam, JSON.stringify(data.ssIdParam));
      this.localStorage.set(this.uIdParam, JSON.stringify(data.uIdParam));
      // console.log(data);
    }
  }



  // Define a method to handle API calls
  saveDataBeforeUnload(data: any): Observable<any> {
    // const url = 'https://your-api-endpoint.com/api/saveData';
    return this.http.post(environment.baseBusinessUrl + `analytics/end/session/log?sessionId=${this.sessionId}&pageId=${data.pageId}`, data, data);
  }

  pageHilighter(data: any) {

    data?.components[0]?.content.navbarButtons.forEach((element: any) => {
      if (element.redirectionUrl === '') {
        element.redirectionUrl = "/"
      }

      if (!element.folder) {
        if (element.redirectionUrl === window.location.pathname || (element.redirectionUrlV2 == window.location.pathname.split("/")[1])) {
          element.status = true
        }
        else {
          element.status = false
        }
      } else {
        let isPageFound = false;
        for (let page of element.folder.pages) {
          if (page.redirectionUrl === window.location.pathname) {
            isPageFound = true;
            break;
          }
        }
        if (isPageFound)
          element.status = true;
        else
          element.status = false;
      }
    });
  }


  setFontFamilies(data: any) {

    if (data?.fonts?.length > 0) {
      Object.keys(data?.fonts[0]).forEach((key, index) => {
        const value = data?.fonts[0][key];
        document.documentElement.style.setProperty('--bs-head-font-family', key);
        document.documentElement.style.setProperty('--bs-head-font-weight', value);
      });
    }
    if (data?.fonts?.length > 1) {
      Object.keys(data?.fonts[1]).forEach((key, index) => {
        const value = data?.fonts[1][key];
        document.documentElement.style.setProperty('--bs-body-font-family', key);
        document.documentElement.style.setProperty('--bs-body-font-weight', value);
      })
    }
  }

  getHeaderAndFooter() {
    let bId = this.localStorage.get('bId');
    return this.http.put(
      environment.baseUrl + `business/v3/business?businessId=${bId}`, ''
    )
  }

  async getBlogSynchronousByBuisnessId(businessId?: any, subIndustryId?: any): Promise<any> {
    if (businessId) {
      return await this.http.get<any>(
        environment.baseUrl + `business/blog/by/business?businessId=${businessId}`
      ).toPromise()
    } else {
      return await this.http.get<any>(
        environment.baseUrl + `business/blog/by/business?subId=${subIndustryId}`
      ).toPromise()
    }
  }

  async getBlogByBlogId(blogId: any) {
    return await this.http.put(
      environment.baseUrl + `business/blog/update/${blogId}`, ''
    ).toPromise()
  }

  async viewBlog(slug:any){
    return await this.http.get(
      environment.baseUrl+`business/blog/search?newPostTitle=${slug}`
    ).toPromise()
    // console.log(slug)
  }

  updateFavicon(iconUrl: string): void {
    const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  checkPaymentStatus(payload: any) {
    return this.http.post(environment.baseUrl + `ecommerce/payment/status`, payload);
  }


  // getAllCategories() {

  //   const payload: any = {
  //     pageNo: 0,
  //     pageSize: 10,
  //     businessId: Business.businessId
  //   }
  //   return this.http.put(environment + 'ecommerce/inventory/get/category', payload).pipe(map((response: any) => response.data.data.map((category: any) => new Category(category))));
  // }
  // getAllCollections() {
  //   const payload: any = {
  //     pageNo: 0,
  //     pageSize: 10,
  //     businessId: Business.businessId
  //   }
  //   return this.http.put(environment + 'ecommerce/inventory/get/collection', payload).pipe(map((response: any) => response.data.data.map((collection: any) => new Collection(collection))));
  // }

}
