import { Component, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import {
  AddNewSectionComponent,
  BannerGridSectionComponent,
  BannerSectionComponent,
  BlogListComponent,
  CartComponent,
  ChooseUsSectionComponent,
  ContactUsComponent,
  FaqSectionComponent,
  FeaturedCategoryComponent,
  FeaturedProductsComponent,
  FeaturesSectionComponent,
  FooterSectionComponent,
  HeaderTextComponent,
  ImageCarouselSectionComponent,
  ImageGridSectionComponent,
  ImageSectionComponent,
  LocationSectionComponent,
  LogoShowcaseComponent,
  NavbarSectionComponent,
  PricingSectionComponent,
  ProcessModernComponent,
  ProcessSectionComponent,
  ProductDescComponent,
  ProductListComponent,
  RecentBlogPostSectionComponent,
  ServiceSectionComponent,
  TeamMemberSectionComponent,
  TestimonialFullwidthComponent,
  TestimonialSectionComponent,
  TextImageSectionComponent,
  TextSectionComponent,
  UserProfileComponent,
  VideoSectionComponent,
  ViewBlogComponent,
  WhislistComponent,
  VerifyComponent,
  AuthenticateUserComponent,
  ProductCategoryListComponent,
  CategoryProductComponent,
  FeaturedCollectionComponent,
  AuthenticationRequiredComponent,
  BannerCarouselComponent,
} from 'simpo-component-library';
import { HomeService } from './home.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPickupService {
  componentsReferences = Array<ComponentRef<any>>();
  constructor(
    private blogService: HomeService
  ) {
  }

  listComponents: any = [
    {
      sectionType: 'banner',
      component: BannerSectionComponent,
    },
    {
      sectionType: 'text',
      component: TextSectionComponent,
    },
    {
      sectionType: 'image',
      component: ImageSectionComponent,
    },
    {
      sectionType: 'text_image',
      component: TextImageSectionComponent,
    },
    {
      sectionType: 'service',
      component: ServiceSectionComponent,
    },
    {
      sectionType: 'testimonial',
      component: TestimonialSectionComponent,
    },
    {
      sectionType: 'imagegrid',
      component: ImageGridSectionComponent,
    },
    {
      sectionType: 'imageCarousel',
      component: ImageCarouselSectionComponent,
    },
    {
      sectionType: 'video',
      component: VideoSectionComponent,
    },
    {
      sectionType: 'teammember',
      component: TeamMemberSectionComponent,
    },
    {
      sectionType: 'header',
      component: NavbarSectionComponent,
    },
    {
      sectionType: 'footer',
      component: FooterSectionComponent,
    },
    {
      sectionType: 'addNewSection',
      component: AddNewSectionComponent,
    },
    {
      sectionType: 'contact us',
      component : ContactUsComponent
    },
    {
      sectionType: "faq",
      component: FaqSectionComponent
    },
    {
      sectionType: "bannerGrid",
      component: BannerGridSectionComponent
    },
    {
      sectionType: "chooseUs",
      component: ChooseUsSectionComponent
    },
    {
      sectionType: "pricing",
      component: PricingSectionComponent
    },
    {
      sectionType: "headerText",
      component: HeaderTextComponent
    },
    {
      sectionType: 'location',
      component: LocationSectionComponent
    },
    {
      sectionType: 'blogPost',
      component: RecentBlogPostSectionComponent
    },
    {
      sectionType: 'blogList',
      component: BlogListComponent
    },
    {
      sectionType: 'viewBlog',
      component: ViewBlogComponent
    },
    {
      sectionType: 'process',
      component: ProcessSectionComponent
    },
    {
      sectionType: 'processModern',
      component: ProcessModernComponent
    },
    {
      sectionType: 'feature',
      component: FeaturesSectionComponent
    },
    {
      sectionType: 'testimonialFullWidth',
      component: TestimonialFullwidthComponent
    },
    {
      sectionType: 'logoShowcase',
      component: LogoShowcaseComponent
    },
    {
      sectionType: 'featuredProduct',
      component: FeaturedProductsComponent
    },
    {
      sectionType: 'featuredCategory',
      component: FeaturedCategoryComponent
    },
    {
      sectionType: 'productDescription',
      component: ProductDescComponent
    },
    {
      sectionType: 'cart',
      component: CartComponent
    },
    {
      sectionType: 'productList',
      component: ProductListComponent
    },
    {
      sectionType: 'wishlist',
      component: WhislistComponent
    },
    {
      sectionType: 'profile',
      component: UserProfileComponent
    },
    {
      sectionType: 'verify-payment',
      component: VerifyComponent
    },
    {
      sectionType: 'authenticate',
      component: AuthenticateUserComponent
    },
    {
      sectionType: 'productCategoryList',
      component: ProductCategoryListComponent
    },
    {
      sectionType: 'categoryProduct',
      component: CategoryProductComponent
    },
    {
      sectionType: 'featuredCollection',
      component: FeaturedCollectionComponent
    },
    {
      sectionType: 'authenticationRequired',
      component: AuthenticationRequiredComponent
    },
    {
      sectionType: 'bannerCarousel',
      component: BannerCarouselComponent
    }
  ];

  createDynamicComponents(
    displaySection: ViewContainerRef | any,
    sectionList: any[],
    edit: boolean,
    loginData?: any
  ) {
    this.componentsReferences = [];
    displaySection?.clear();
    sectionList?.forEach((section, index) => {
      for (const component of this.listComponents) {
        if (component.sectionType === section.sectionType && !(component.sectionType.includes('footer') && window.innerWidth <= 475 && (localStorage.getItem("websiteType") == "E_COMMERCE"))) {
          let responseData;
          if(component.sectionType === 'blogPost' || component.sectionType === 'blogList') {
            this.updateSection(displaySection, index, section, edit)
            continue;
          }
          if(component.sectionType === 'viewBlog') {
            this.addComponent(displaySection, section,edit, index)
            continue;
          }


          const k = displaySection.createComponent(component.component);
          k.instance.data = section;
          k.instance.index = index;
          k.instance.edit = edit;

          if(loginData)
            k.instance.loginData = loginData

          if(section.sectionType === 'header') {
            k.instance.nextComponent = sectionList[1];
            k.instance.data?.content?.navbarButtons.forEach((button: any)=> {
              if (button?.label?.toLowerCase()?.includes("terms") || button?.label?.toLowerCase()?.includes("privacy") || button?.label?.toLowerCase()?.includes("faq"))
                button.redirectionUrlV2 = button?.label?.replaceAll(" ", "-")?.replaceAll("\'", "")?.toLowerCase();
            })
          }
          console.log("index: ", index);
          if (index == (sectionList.length -2) && window.innerWidth <= 475 && (localStorage.getItem("websiteType") == "E_COMMERCE") ) {
            if (window.innerWidth <= 475) {
              k.instance.customClass = "margin-bottom: 70px;";
            }
          }

          this.componentsReferences.push(k);
          break;
        }
      }
    });

    return this.componentsReferences?.[0]?.instance?._eventService || null;
  }

  updateSection(displaySection: ViewContainerRef | any,index:number,newData:any,edit: boolean, sectionType?: any){
    const componentToRemove = displaySection.get(index);
    if (componentToRemove) {
      componentToRemove.destroy();
    }

    this.componentsReferences.splice(index,1);
    this.listComponents.forEach(async (listCompos: any, i: number) => {
      if (listCompos.sectionType === newData.sectionType) {
        let responseData;
        if(listCompos.sectionType === 'blogPost' || listCompos.sectionType === 'blogList') {
          let businessId = localStorage.getItem('bId');
          if(businessId) {
            responseData = await this.blogService.getBlogSynchronousByBuisnessId(businessId)
          }

        }
        const k = displaySection.createComponent(listCompos.component, {index});
        k.instance.data = newData;
        if(index == 1) {
          displaySection._lContainer[10][8].nextComponent = newData
        }
        k.instance.edit = edit;
        if(responseData) {
          k.instance.responseData = responseData.data;
        }
        if(sectionType) k.instance.sectionType = sectionType;
        k.instance.index = index;
        this.componentsReferences.splice(index, 0, k);

      }
    });
    return this.componentsReferences?.[0]?.instance?._eventService || null;
  }

  addComponent(displaySection: ViewContainerRef | any, newComponment: any, edit:boolean, index:number, sectionType?: any) {
    let componentFact = this.listComponents.forEach(async (listCompos: any, i: number) => {
      if (listCompos.sectionType === newComponment.sectionType) {
        let responseData: any;
        if(newComponment.sectionType === 'viewBlog') {
          // responseData = await this.blogService.getBlogByBlogId(newComponment.blogId)
          const res:any = await this.blogService.viewBlog(newComponment.blogId);
          responseData  = {
            data:res.data[0]
          }
        }

        const k = displaySection.createComponent(listCompos.component, {index});
        k.instance.data = newComponment;

        if(responseData) {
          k.instance.responseData = responseData.data;
        }

        if(sectionType) k.instance.sectionType = sectionType;

        k.instance.edit = edit;

        k.instance.canEditAll = true;
        k.instance.index = index;

        this.componentsReferences.splice(index, 0, k);

        for(let i = index + 10 + 1;i<displaySection._lContainer.length;i++){
          if(displaySection._lContainer[i][8].index){
            displaySection._lContainer[i][8].index = displaySection._lContainer[i][8].index + 1;
          }
        }

      }
    });
    return this.componentsReferences?.[0]?.instance?._eventService || null;
  }

}
